import type { CommonHeroIconProps } from "./common-hero-icon-props.types";

/**
 * The solid 24x24 'x-mark' icon from [HeroIcons 2.0](https://heroicons.com).
 */
export function SolidXMarkIcon({
  className
}: CommonHeroIconProps) {
  return <svg className={className} fill="currentColor" viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-component="SolidXMarkIcon" data-sentry-source-file="SolidXmarkIcon.component.tsx">
      <path clipRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" fillRule="evenodd" data-sentry-element="path" data-sentry-source-file="SolidXmarkIcon.component.tsx" />
    </svg>;
}