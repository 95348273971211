"use client";

import { SolidXMarkIcon } from "@/components/Icons/components/HeroIcons/SolidXmarkIcon.component.tsx";
import { useSite } from "@/contexts/site/site.context";
import { clsx } from "clsx";
import type { MouseEventHandler } from "react";
type ClearButtonProps = {
  readonly className?: string;
  readonly onMouseDown?: MouseEventHandler<HTMLButtonElement>;
};
export function ClearButton({
  className,
  onMouseDown
}: ClearButtonProps) {
  const {
    locale
  } = useSite();
  return <button className={clsx(className, "flex h-2.5 w-2.5 items-center rounded-full bg-dim p-px transition-all hover:bg-nero")} onMouseDown={event => {
    event.preventDefault();
    onMouseDown?.(event);
  }} tabIndex={-1} // Keyboard accessibility has been omitted for now.
  type="button" data-sentry-component="ClearButton" data-sentry-source-file="ClearButton.component.tsx">
      <span className="sr-only">
        {{
        da: "Ryd",
        de: "Löschen",
        en: "Clear",
        no: "Slett",
        sv: "Rensa"
      }[locale]}
      </span>
      <SolidXMarkIcon className="h-full w-full fill-white" data-sentry-element="SolidXMarkIcon" data-sentry-source-file="ClearButton.component.tsx" />
    </button>;
}