"use client";

import clsx from "clsx";
import { forwardRef } from "react";
import type { ForwardRefExoticComponent, ForwardedRef, InputHTMLAttributes, RefAttributes } from "react";
import { ClearButton } from "./components/ClearButton/ClearButton.component";
type WithClearButtonProps = InputHTMLAttributes<HTMLInputElement> & {
  readonly ref?: ForwardedRef<HTMLInputElement>;
  readonly onClear?: () => void;
};
export function withClearButton(Component: ForwardRefExoticComponent<Omit<WithClearButtonProps, "ref"> & RefAttributes<HTMLInputElement>>) {
  function ComponentWithClearButton({
    className,
    onClear,
    ...rest
  }: WithClearButtonProps, ref: ForwardedRef<HTMLInputElement>) {
    return <>
        <Component className={clsx(className, "peer")} ref={ref} {...rest} data-sentry-element="Component" data-sentry-source-file="WithClearButton.component.tsx" />
        <ClearButton className={clsx("absolute right-1 top-1/2 z-20 -translate-y-1/2", "invisible opacity-0 focus:visible focus:opacity-100 peer-focus:not-peer-placeholder-shown:visible peer-focus:not-peer-placeholder-shown:opacity-100", "peer-focus:not-peer-placeholder-shown:peer-read-only:invisible peer-focus:not-peer-placeholder-shown:peer-disabled:invisible")} onMouseDown={() => {
        onClear?.();
      }} // Using `onMouseDown` instead of `onClick` prevents the button from stealing focus from the input field.
      data-sentry-element="ClearButton" data-sentry-source-file="WithClearButton.component.tsx" />
      </>;
  }
  return forwardRef<HTMLInputElement, WithClearButtonProps>(ComponentWithClearButton);
}